@keyframes blink-caret {
    from, to {
        opacity: 0
    }
    50% {
        opacity: 1;
    }
}

.typingCursor {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.7);
    width: 4px;
    margin-left: 2px;
    margin-bottom: -2px;
    animation: blink-caret .7s step-end infinite;
    z-index: 99;
}

.roboto {
    font-family: 'Roboto Mono', monospace;
}

.ptmono {
    font-family: 'PT Mono', monospace;
}

.hidden_portal {
    pointer-events: none !important;
}

.web3modal-modal-lightbox {
    z-index: 100 !important;
}

.web3modal-modal-card {
    border-radius: 0 !important;
    border: 1px solid #00ff54;
    background-color: black !important;
}

.walletconnect-modal__base {
    border-radius: 0 !important;
    border: 1px solid #00ff54;
    background-color: black !important;
}

.web3modal-provider-container {
    border-radius: 0 !important;
    background: initial !important;
}

.web3modal-provider-container:hover {
    background: rgba(255, 255, 255, 0.1) !important;
}

.web3modal-provider-name {
    color: white !important;
}

.walletconnect-qrcode__text {
    color: white !important;
}

.walletconnect-modal__base__row > h3 {
    color: white !important;
}

.walletconnect-modal__mobile__toggle {
    background-color: black !important;
    border: 1px solid #00ff54;
    border-radius: 0 !important;
}

.walletconnect-modal__mobile__toggle_selector {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 0 !important;
}

.delayed-reveal {
    animation-name: phase-slide-in;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
}

@keyframes phase-slide-in {
    0% {
        opacity: 1;
        transform: translateY(-100%);
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}
